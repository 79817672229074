import React, {useEffect, useState} from 'react';
import Heading from './../Heading/Heading';
import Paragraph from './../Paragraph/Paragraph';
import Facebook from './../../icons/Facebook';
import Arrow from './../../icons/Arrow';
import Twitter from './../../icons/Twitter';
import Linkedin from './../../icons/Linkedin';
import Instagram from './../../icons/Instagram';
import QuadFooter from './../../icons/QuadFooter.svg';

const Footer = ({site}) => {
    return(<footer>
        <h1></h1><h2></h2><h3></h3><h4></h4>
        {!!site && <>
            <section className="footer-contents-main grid">
                <div className="form-container">
                {!!site.mailchimp && <Heading level={5} className={"c-secondary"} content={"Sign up to stay up to date"}/> }
                    {!!site.mailchimp && <form className="subscribe-form">
                        <label htmlFor="search">Email</label>
                        <div role="group">
                            <input id="search" placeholder="Enter email address" type="text" />
                        </div>
                        <button className="button style-primary" type="submit">Submit</button>
                    </form>}
                </div>
                <div className="contact">
                    <Heading level={5} className={"c-secondary"} content={"Property Management Office"}/>
                    <Paragraph className={"c-background"} content={site.address || ''}/>
                    {/* <Paragraph className={"c-background"} content={"Commerce Court"}/>
                    <Paragraph className={"c-background"} content={"199 Bay Street, Suite 1910"}/>
                    <Paragraph className={"c-background"} content={"Toronto, ON  M5L 1E2"}/> */}
                    <a href="/get-in-touch">Getting Here</a>
                </div>
                <div className="social-container">
                    <Heading level={5} className={"c-secondary"} content={"Follow Us"}/>
                    <div className="socials">
                        {!!site.facebook && <a target="_blank" href={site.facebook}><Facebook /></a>}
                        {!!site.linkedin && <a target="_blank" href={site.linkedin}><Linkedin /></a>}
                        {!!site.twitter && <a target="_blank" href={site.twitter}><Twitter /></a>}
                        {!!site.instagram && <a target="_blank" href={site.instagram}><Instagram /></a>}
                    </div>
                    <div className="site-logo">
                        <Heading level={6} className={"c-background"} content={"Proudly Managed By:"}/>
                        <div className="logo-container">
                            <a href="https://quadreal.com" target="_blank" title="Quadreal Logo"><img src={site.footerLogo} alt="Quadreal Logo" /></a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="footer-contents-sub grid">
                <span className="divider"></span>2
                <a className="additional-link-pal" href="https://www.quadrealconnect.com/">
                    <img src="//crew-quadreal-cc.azurewebsites.net/img/cc/cc-connect.png" width="90px" alt="Quadreal Connect"/>
                </a>
                <ul className="additional-links">
                    <li><a href="https://www.quadreal.com/accessibility-policy/">Accessibility</a></li>
                    <li><a href="https://www.quadreal.com/privacy-policy-quadreal-finance/">Legal</a></li>
                    <li><a href="https://www.quadreal.com/legal/">Privacy Policy</a></li>
                </ul>
                <span className="copyright">© {new Date().getFullYear()}, QuadReal Property Group Limited Partnership. All Rights Reserved.</span>
                <a href="#" className="navigate-top">Top <span><Arrow fill={'background'} width={'12'} /></span></a>
            </section>
        </>}
    </footer>)
}

export default Footer;